<template>
    <div class="flex img-mini-box items-center">
        <img class="img-mini object-contain"  :src="params.value" alt="">
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="css" scoped>
.img-mini {
    height: 50px;
    width: auto;
}
.img-mini-box{
    height: 50px;
    width: auto;
}
</style>