<template>
  <div class="contentArea w-full" style="color: #6A7E91; ">

    <div class="bg-white flex flex-col p-6">

      <!-- header -->
      <div class="mb-5 flex flex-col gap-4 lg:flex-row  grow-0 sm:justify-between items-start">
        <div class="flex w-full lg:w-5/12  flex-col gap-4">
          <div class="flex  items-center gap-2">
            <span class="font-semibold  whitespace-no-wrap ">Brand name:</span>
            <input class="text-input w-full" v-model="brand.brand_name" type="text" />  
            <img class="pointer w-10 h-10"  src="@/assets/images/elements/check.svg" alt=""
              @click="updateBrand({ brand_name: brand.brand_name })" />         
          </div>
          
          <!-- <div class="flex items-center gap-2">
            <span class="font-semibold whitespace-no-wrap">URL name:</span>
            <input class="text-input" v-model="brand.brand_url" type="text" />
            <img
              src="@/assets/images/elements/pensil.svg"
              alt=""
              @click="updateBrand({ brand_url: brand.brand_url })"
            />
          </div> -->
        </div>
        <div class="lg:w-5/12 flex flex-col items-end gap-3 w-full">
          <div class="flex flex-col md:w-8/12 lg:w-7/12 w-full gap-3 justify-end cus-select ">
            <div class="w-full" >
              <b-form-select class="w-full" v-model="initBrand.is_publish" @change="updateBrandIsPublish" :options="[{text:'Publish' ,value:true} , {text:'Unpublish' ,value:false}]"></b-form-select>
              <p v-show="update-warn" class="mb-0" style="font-size: 0.75rem; color: #f40909; text-align: right;">Please click the Update button to update the data</p> 
            </div>
            <div class="flex gap-3 w-full">
              <button @click="openPreviewBrand" class="border-button border-button-pri w-full">Preview</button>
              <button @click="updateBrandHtml" class="border-button border-button-pri w-full">Update</button>
            </div>
           
          </div>
          <div class="flex w-full gap-2">
            <input class="text-input" ref="brand_url" v-model="brand.brand_url" readonly placeholder="url"
              type="text" />
            <button @click="copy" class="border-button border-button-pri">copy</button>
          </div>

          <p v-if="initBrand.lastest_update" class="text-right mt-4">Lastest update : {{ formatDate }}</p>
        </div>
      </div>

      <!-- upload -->
      <span class="font-semibold">Upload images</span>
      <div class="my-8 grow-0 flex flex-col gap-4 items-center lg:flex-row sm:justify-around">
        <!-- Thumbnail -->
        <div>
          <div class="img-box ">
            <img v-if="thumbnail_img_url" @click="OpenImageModal(addTimeStamp(thumbnail_img_url))" class="object-contain img-element pointer" :src="addTimeStamp(thumbnail_img_url)"
              alt="" />
            <div class="flex items-center justify-center h-full" v-else>
              <img @click="$refs.uploadThumbnailImg.click()" src="@/assets/images/elements/plus.svg" alt="" />
            </div>
            <input style="display: none" ref="uploadThumbnailImg" accept="image/png, image/gif, image/jpeg"
              @change="onFileThumbnailSelected($event)" type="file" />
          </div>
          <div class="flex justify-center mt-3 gap-3">
            Thumbnail
            <img @click="$refs.uploadThumbnailImg.click()" class="pointer" src="@/assets/images/elements/pensil.svg" alt="" />
            <img @click="deleteThumbnailImg" class="pointer" src="@/assets/images/icon/del.svg" alt="" />
          </div>
        </div>

        <!-- header -->
        <div>
          <div class="img-box">
            <img v-if="header_img_url" @click="OpenImageModal(addTimeStamp(header_img_url))" class="object-contain img-element pointer" :src="addTimeStamp(header_img_url)" alt="" />
            <div class="flex items-center justify-center h-full" v-else>
              <img @click="$refs.uploadHeaderImg.click()" src="@/assets/images/elements/plus.svg" alt="" />
            </div>
            <input style="display: none" ref="uploadHeaderImg" accept="image/png, image/gif, image/jpeg"
              @change="onFileHeaderSelected($event)" type="file" />
          </div>
          <div class="flex justify-center mt-3 gap-3">
            Header
            <img @click="$refs.uploadHeaderImg.click()" class="pointer" src="@/assets/images/elements/pensil.svg" alt="" />
            <img @click="deleteHeaderImg()" class="pointer" src="@/assets/images/icon/del.svg" alt="" />
          </div>
        </div>

        <!-- footer -->
        <div>
          <div class="img-box">
            <img v-if="foorer_img_url" @click="OpenImageModal(addTimeStamp(foorer_img_url))" class="object-contain img-element pointer" :src="addTimeStamp(foorer_img_url)" alt="" />
            <div class="flex items-center justify-center h-full" v-else>
              <img @click="$refs.uploadFooterImg.click()" src="@/assets/images/elements/plus.svg" alt="" />
            </div>
            <input style="display: none" ref="uploadFooterImg" accept="image/png, image/gif, image/jpeg"
              @change="onFileFooterSelected($event)" type="file" />
          </div>
          <div class="flex justify-center mt-3 gap-3">
            Footer
            <img @click="$refs.uploadFooterImg.click()" class="pointer" src="@/assets/images/elements/pensil.svg" alt="" />
            <img @click="deleteFooterImg" class="pointer" src="@/assets/images/icon/del.svg" alt="" />
          </div>
        </div>
      </div>

      <!-- Products -->
      <span class="font-semibold">Products</span>
      <div class="flex flex-col gap-4 xl:flex-row sm:justify-between grow-0 my-5">
        <div class="grid grid-cols-3 lg:flex gap-2 cus-dropdown">
          <button class="fill-button fill-button-pri text-sm" @click="openCreateProductModal">
            Create New Product
          </button>
          <b-dropdown size="sm" variant="outline-secondary" class="" text="Multi-upload">
            <b-dropdown-item @click="$refs.uploadProductExcel.click()">Product Information</b-dropdown-item>
            <b-dropdown-item @click="$refs.uploadChunkProductImg.click()">Product Images</b-dropdown-item>
          </b-dropdown>

          <input style="display: none" ref="uploadProductExcel"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="onFileExcelselected($event)" type="file" />
          <input style="display: none" ref="uploadChunkProductImg" accept="image/png, image/gif, image/jpeg"
            @change="onMultiFileProductSelected($event)" type="file" multiple />

          <button class="border-button border-button-pri text-sm" @click="exportProduct">Export Products</button>
          <button class="border-button border-button-pri text-sm" :disabled="!allowClick" :class="{'border-button-disable':!allowClick}" @click="publishProduct">
            Publish
          </button>
          <button class="border-button border-button-pri text-sm" :disabled="!allowClick" :class="{'border-button-disable':!allowClick}"  @click="unPublishProduct">
            Unpublish
          </button>
          <button class="border-button border-button-red text-sm" :disabled="!allowClick" :class="{'border-button-disable':!allowClick}"  @click="openDeleteProductModal">
            Delete
          </button>
        </div>
        <div>
          <input class="text-input" v-model="search_text" placeholder="search" />
        </div>
      </div>

      <!-- <BrandTable /> -->
      <ProductTable ref="productTable" @AllowClick="isAllowClick" :rowData="rowData" :searchText="search_text" @edit-value="openEditProductModal"
        @product-down="ProductDown" @product-up="ProductUp" />

      <!-- Create Product Modal -->
      <BlankModal v-if="showCreateProductModal" @close="closeCreateProductModal">
        <template v-slot:body>
          <div class="p-4 container">
            <form @submit.prevent="createProduct">
              <p class="font-semibold text-center">New Product</p>
              <div class="flex flex-col items-center justify-center">
                <!-- Thumbnail -->
                <div class="img-box ">
                  <img v-if="product_img_pre" class="object-contain img-element" :src="product_img_pre" alt="" />
                  <div class="flex items-center justify-center h-full" v-else>
                    <img @click="$refs.uploadProductImg.click()" src="@/assets/images/elements/plus.svg" alt="" />
                  </div>
                  <input style="display: none" ref="uploadProductImg" accept="image/png, image/gif, image/jpeg"
                    @change="onFileProductSelected($event)" type="file" />
                </div>
                <div class="flex gap-3 font-semibold my-2">
                  Thumbnail
                  <img @click="$refs.uploadProductImg.click()" class="pointer" src="@/assets/images/elements/pensil.svg" alt="" />
                  <img @click="deleteProductImg()" class="pointer" src="@/assets/images/icon/del.svg" alt="" />
                </div>

                <div class="mb-5">Maximum image size: 10 MB</div>
              </div>
              <div class="flex flex-col gap-4 mb-4">
                <div>
                  <label for="sku-input">SKU</label>
                  <input class="text-input" id="sku-input" v-model="new_product.product_sku" placeholder="SKU" required type="text" />
                </div>
                <div>
                  <label for="product-input">Product name</label>
                  <input class="text-input" id="product-input" v-model="new_product.product_name" placeholder="Product name" required
                  type="text" />
                </div>
                
                <div class="flex gap-4 w-full">
                  <div class="w-full">
                    <label for="price-input">Full price</label>
                    <input class="text-input" id="price-input" v-model="new_product.price" placeholder="Full price" type="number"
                    required />
                  </div>
                    <div class="w-full">
                      <label for="special-input">Special price</label>
                      <input class="text-input" id="special-input" v-model="new_product.special_price" placeholder="Special price"
                    type="number" />
                    </div>
                  
                </div>
              </div>

              <div class="flex justify-center gap-4">
                <button @click="closeCreateProductModal" class="border-button border-button-red" type="button">
                  Cancel
                </button>
                <button class="border-button border-button-pri" type="submit">
                  Create
                </button>
              </div>
            </form>
          </div>
        </template>
      </BlankModal>
      <!-- Edit Product Modal -->
      <BlankModal v-if="showEditProductModal" @close="closeEditProductModal">
        <template v-slot:body>
          <div class="p-4 container">
            <form @submit.prevent="editProduct">
              <p class="font-semibold text-center">Product</p>
              <div class="flex flex-col items-center justify-center">
                <!-- Thumbnail -->
                <div class="img-box ">
                  <img v-if="edit_product.product_image && !product_img" class="object-contain img-element"
                    :src="edit_product.product_image" alt="" />
                  <img v-else-if="product_img" class="object-contain img-element" :src="product_img_pre" alt="" />
                  <div class="flex items-center justify-center h-full" v-else>
                    <img @click="$refs.uploadProductImg.click()" src="@/assets/images/elements/plus.svg" alt="" />
                  </div>
                  <input style="display: none" ref="uploadProductImg" accept="image/png, image/gif, image/jpeg"
                    @change="onFileProductSelected($event)" type="file" />
                </div>
                <div class="flex gap-3 font-semibold my-2">
                  Thumbnail
                  <img @click="$refs.uploadProductImg.click()" class="pointer" src="@/assets/images/elements/pensil.svg" alt="" />
                  <img @click="deleteProductImg()" class="pointer" src="@/assets/images/icon/del.svg" alt="" />
                </div>

                <div class="mb-5">Maximum image size: 10 MB</div>
              </div>
              <div class="flex flex-col gap-4 mb-4">
                <div>
                  <label for="sku-input">SKU</label>
                  <input class="text-input" id="sku-input" v-model="edit_product.product_sku" placeholder="SKU" type="text" />
                </div>
                <div>
                  <label for="brand-input">Brand name</label>
                  <input class="text-input" id="brand-input" v-model="edit_product.product_name" placeholder="Brand name" type="text" />
                </div>
          
               
                <div class="flex gap-4 w-full">
                  <div class="w-full">
                    <label for="price-input">Full price</label>
                    <input class="text-input" id="price-input" v-model="edit_product.price" placeholder="Full price" type="number" />
                  </div>
                  <div class="w-full">
                    <label for="special-price-input">Special price</label>
                    <input class="text-input" id="special-price-input" v-model="edit_product.special_price" placeholder="Special price"
                    type="number" />
                  </div>
                 
                </div>
              </div>

              <div class="flex justify-center gap-4">
                <button @click="closeEditProductModal" class="border-button border-button-red" type="button">
                  Cancel
                </button>
                <button class="border-button border-button-pri" type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </template>
      </BlankModal>

      <!-- Delete Modal -->
      <MiniBlankModal v-if="showDeleteProductModal" @close="closeDeleteProductModal">
        <template v-slot:body>
          <div class="flex flex-col gap-4 p-4">
            <p class="font-semibold text-center">
              Are you sure that you want to delete this product?
            </p>

            <div class="flex justify-around">
              <button @click="closeDeleteProductModal" style="background-color: rgb(235, 235, 235);"
                class="border-button border-button-pri">
                Cancel
              </button>
              <button @click="deleteProduct" style="background-color: red; color:#FFFFFF;"
                class="border-button border-button-pri">
                Delete
              </button>
            </div>
          </div>
        </template>
      </MiniBlankModal>

      <!-- Preview Brand -->
      <BlankModal v-if="showPreviewBrand" @close="closePreviewBrand">
        <template v-slot:body>
          <div v-html="previewBrand"></div>
        </template>
      </BlankModal>

      <!-- Preview Image -->
      <BlankModal v-if="showPreviewImage" @close="closePreviewImage">
        <template v-slot:body>
          <div class="w-full flex justify-center img-modal">
            <img class="object-contain img-modal" :src="modal_img_url" alt="">
          </div>
        </template>
      </BlankModal>
    </div>
  </div>
</template>

<script>
import AgGrid from "../elements/ag-grid-table/AgGridTable.vue";
import ProductTable from "../elements/ag-grid-table/ProductTable.vue";
import BlankModal from "../../components/blankModal.vue";
import MiniBlankModal from "../../components/miniblankModal.vue";
import axios from "../../axios";
import { baseurl } from "../../util/baseUrl";
import moment from "moment";
import Compressor from "compressorjs";

export default {
  // async created() {
  //   console.log("this.initBrand:",this.initBrand);
  //   await this.$store.dispatch("general/push_activePage", {
  //     url: "/",
  //     name: this.initBrand.brand_name,
  //     slug: "brand",
  //   });
  // },
  data() {
    return {
      search_text: null,
      rowData: [],
      showCreateProductModal: false,
      showDeleteProductModal: false,
      showEditProductModal: false,
      initBrand: null,
      brand: {
        brand_id: null,
        brand_name: "",
        brand_url: "",
      },

      brandname_change:false,
      dropdown: false,

      new_product: {
        product_image: null,
        product_sku: null,
        product_name: null,
        price: null,
        special_price: null,
      },

      edit_product: {
        product_id: null,
        product_image: null,
        product_sku: null,
        product_name: null,
        price: null,
        special_price: null,
      },

      previewBrand: null,
      showPreviewBrand: false,
      showPreviewImage: false,


      delete_product_arr: null,

      product_img: null,
      product_img_pre: null,
      product_img_url: null,

      footer_img: null,
      foorer_img_url: null,
      header_img: null,
      header_img_url: null,
      thumbnail_img: null,
      thumbnail_img_url: null,

      modal_img_url: null,

      multi_product_img: [],

      allowClick: false
    };
  },
  async beforeMount() {
    this.brand.brand_id = this.$route.params.brand_id;
    await this.getBrand();
    await this.$store.dispatch("general/push_activePage",{
        name: this.initBrand.brand_name
         })
  },

  methods: {
    async compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 1,
          maxHeight: 12000,
          success(result) {
            resolve(result);
          },
          error(e) {
            reject(e);
          },
        });
      });
    },
    async uploadFileBrand(file, brandname, campaignname, type) {
      const fileforupload = await this.compressImage(file);
      let fd = new FormData();
      fd.append("brand_name", brandname);

      if (type) {
        fd.append("img_type", type);
      }
      fd.append("campaign_name", campaignname)
      fd.append("file", fileforupload);
      try {
        let res = await axios.post(
          `${baseurl()}/api/website/uploadImg`,
          fd
        );
        return res.data.url;
      } catch (error) {
        throw error;
      }
    },
    openCreateProductModal() {
      console.log("open");
      this.showCreateProductModal = true;
    },
    closeCreateProductModal() {
      this.new_product = {
        product_image: null,
        product_sku: null,
        product_name: null,
        price: null,
        special_price: null,
      };
      this.product_img = null
      this.product_img_pre = null
      this.product_img_url = null

      this.showCreateProductModal = false;
    },
    openEditProductModal(value) {
      console.log("showEditProductModal", value.product_id);
      this.edit_product = {
        product_id: value.product_id,
        product_image: value.product_img,
        product_sku: value.product_sku,
        product_name: value.product_name,
        price: value.price,
        special_price: value.special_price,
      }
      this.showEditProductModal = true
    },
    closeEditProductModal() {
      this.edit_product = {
        product_id: null,
        product_image: null,
        product_sku: null,
        product_name: null,
        price: null,
        special_price: null,
      },
        this.product_img = null
      this.product_img_pre = null
      this.product_img_url = null
      this.showEditProductModal = false
    },

    async openPreviewBrand() {
      try {
        let res = await axios.get(`${baseurl()}/api/website/preview/brand/${this.brand.brand_id}`)
        console.log(res);

        this.previewBrand = res.data
        this.showPreviewBrand = true
      } catch (error) {
        if (error.response.data.message) {
          alert(error.response.data.message)
        }
        else {
          alert(error)
        }
      }
    },

    copy() {
      let url = this.$refs.brand_url
      // url.innerHTML = window.location.href;
      // console.log(url.innerHTML)
      url.select();
      document.execCommand('copy');
    },

    closePreviewBrand() {
      this.previewBrand = null
      this.showPreviewBrand = false
    },

    async createProduct() {
      try {
        const newProductPrice = this.new_product.price ? +this.new_product.price : null
        const newProductSpecialPrice = this.new_product.special_price ? +this.new_product.special_price : null
        if(this.new_product.special_price && newProductPrice <= newProductSpecialPrice){
          alert("Incorrect Pricing")
          return
        }
        
        if (this.product_img) {
          const fileforupload = await this.compressImage(this.product_img);
          let fd = new FormData();
          fd.append("brand_name", this.initBrand.brand_name);
          fd.append("file", fileforupload);
          const { data: img_res } = await axios.post(
            `${baseurl()}/api/website/uploadImg`,
            fd
          );

          await axios.post(`${baseurl()}/api/admin/createProduct`, {
            brand_id: this.brand.brand_id,
            sequence_num: this.rowData.length,
            product_name: this.new_product.product_name,
            product_sku: this.new_product.product_sku,
            price: newProductPrice,
            special_price: newProductSpecialPrice,
            product_img: img_res.url,
          });
        }else{
          await axios.post(`${baseurl()}/api/admin/createProduct`, {
            brand_id: this.brand.brand_id,
            sequence_num: this.rowData.length,
            product_name: this.new_product.product_name,
            product_sku: this.new_product.product_sku,
            price: newProductPrice,
            special_price: newProductSpecialPrice
          });
        }

      } catch (err) {
        console.log(err);
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err);
        }
      }
      this.new_product = {
        product_image: null,
        product_sku: null,
        product_name: null,
        price: null,
        special_price: null,
      };
      this.deleteProductImg();
      this.showCreateProductModal = false;
      this.getBrand();
    },
    async editProduct() {
      try {
        if (this.product_img) {
          const fileforupload = await this.compressImage(this.product_img);
          let fd = new FormData();
          fd.append("file", fileforupload);
          const { data: img_res } = await axios.post(
            `${baseurl()}/api/website/uploadImg`,
            fd
          );
          await axios.put(`${baseurl()}/api/admin/updateProduct`, {
            brand_id: this.brand.brand_id,
            product_id: this.edit_product.product_id,
            product_name: this.edit_product.product_name,
            product_sku: this.edit_product.product_sku,
            price: this.edit_product.price === "" ? null : this.edit_product.price,
            special_price: this.edit_product.special_price === "" ? null : this.edit_product.special_price,
            product_img: img_res.url,
          });
        }
        else {
          await axios.put(`${baseurl()}/api/admin/updateProduct`, {
            brand_id: this.brand.brand_id,
            product_id: this.edit_product.product_id,
            product_name: this.edit_product.product_name,
            product_sku: this.edit_product.product_sku,
            price: this.edit_product.price === "" ? null : this.edit_product.price,
            special_price: this.edit_product.special_price === "" ? null : this.edit_product.special_price,
            product_img: this.edit_product.product_image
          });
        }
        await this.getBrand()
        this.showEditProductModal = false

      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
    },
    openDeleteProductModal() {
      this.showDeleteProductModal = true;
    },
    closeDeleteProductModal() {
      console.log("close");
      this.showDeleteProductModal = false;
    },
    async deleteProduct() {
      const rows = this.$refs.productTable.getSelectedRowData();
      const id_array = rows.map((row) => row.product_id);
      try {
        await axios.delete(`${baseurl()}/api/admin/deleteProductMultiple`, {
          data: {
            product_id: id_array,
          },
        });
      } catch (err) {
        if (err.result) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.showDeleteProductModal = false;
      this.getBrand();
    },
    async getBrand() {
      try {
        let { data } = await axios.get(
          `${baseurl()}/api/admin/getBrand/${this.$route.params.brand_id}`
        );

        console.log(data);

        this.initBrand = data;
        this.brand.brand_name = data.brand_name;
        this.brand.brand_url = data.brand_url;
        this.thumbnail_img_url = data.thumbnail_img;
        this.header_img_url = data.header_img;
        this.foorer_img_url = data.footer_img;
        this.rowData = data.products.map((prod) => {
          return {
            product_id: prod.product_id,
            up: { product_id: prod.product_id, brand_id: this.$route.params.brand_id },
            down: { product_id: prod.product_id, brand_id: this.$route.params.brand_id },
            product_sku: prod.product_sku,
            product_name: prod.product_name,
            price: prod.price ? prod.price.toLocaleString() : "-",
            special_price: prod.special_price ? prod.special_price.toLocaleString() : "-",
            product_img: prod.product_img,
            is_publish: {
              product_id: prod.product_id,
              is_publish: prod.is_publish
            },
            edit: prod,
          };
        });
      } catch (error) {
        if (error.response.data.message) {
          alert(error.response.data.message)
          if (error.response.data.message === 'Not found') {
            this.$router.push({ name: 'campaign' })
          }
        } else {
          alert(error)
        }
      }
    },

    async updateBrand(data) {
      try {
        this.$vs.loading()
        let res = await axios.put(`${baseurl()}/api/admin/updateBrand`, {
          brand_id: this.$route.params.brand_id,
          campaign_id: this.$route.params.campaign_id,
          ...data,
        });
        if (res.data.status) {
          alert(res.data.status)
        }
        this.$vs.loading.close()
      } catch (err) {
        if (err.response.data.message) {
          alert(err.response.data.message);
        } else {
          alert(err);
        }
        this.$vs.loading.close()
      }
      this.getBrand();
    },

    // upload image
    async onFileProductSelected(e) {
      const target = e.target;
      console.log(target.files.length);
      const file = target.files[0];

      console.log("file:", file.type.match("image.*"));
      const FileSize = file.size;

      console.log("size:", FileSize);
      if (!file.type.match("image.*")) {
        alert("Unsuccessful Upload. Please make sure to upload a image file.");
        return;
      }
      if (FileSize > 10000000) {
        alert(
          "Unsuccessful Upload. Please make sure to upload file size less than 10 MB."
        );
        return;
      }

      if (file.type.match("image.*")) {
        console.log(file);
        this.product_img = file;
        this.product_img_pre = await getBase64(file);
      }
      e.target.value = null
    },
    async onFileThumbnailSelected(e) {
      const target = e.target;
      console.log(target.files.length);
      const file = target.files[0];

      console.log("file:", file.type.match("image.*"));
      const FileSize = file.size;

      console.log("size:", FileSize);
      if (!file.type.match("image.*")) {
        alert("Unsuccessful Upload. Please make sure to upload a image file.");
        return;
      }
      if (FileSize > 10000000) {
        alert(
          "Unsuccessful Upload. Please make sure to upload file size less than 10 MB."
        );
        return;
      }

      if (file.type.match("image.*")) {
        console.log("thumnail:", file);
        this.thumbnail_img = file;
        let brand_thumbnail_img_url = await this.uploadFileBrand(
          file,
          this.initBrand.brand_name,
          this.initBrand.campaign.campaign_name,
          "thumbnail"
        );

        this.updateBrand({ thumbnail_img: brand_thumbnail_img_url });
      }
      e.target.value = null
    },
    async onFileHeaderSelected(e) {
      const target = e.target;
      console.log(target.files.length);
      const file = target.files[0];

      console.log("file:", file.type.match("image.*"));
      const FileSize = file.size;

      console.log("size:", FileSize);
      if (!file.type.match("image.*")) {
        alert("Unsuccessful Upload. Please make sure to upload a image file.");
        return;
      }
      if (FileSize > 10000000) {
        alert(
          "Unsuccessful Upload. Please make sure to upload file size less than 10 MB."
        );
        return;
      }

      if (file.type.match("image.*")) {
        console.log("header:", file);
        this.thumbnail_img = file;
        let brand_header_img_url = await this.uploadFileBrand(
          file,
          this.initBrand.brand_name,
          this.initBrand.campaign.campaign_name,
          "header"
        );

        this.updateBrand({ header_img: brand_header_img_url });
      }
      e.target.value = null
    },
    async onFileFooterSelected(e) {
      const target = e.target;
      console.log(target.files.length);
      const file = target.files[0];

      console.log("file:", file.type.match("image.*"));
      const FileSize = file.size;

      console.log("size:", FileSize);
      if (!file.type.match("image.*")) {
        alert("Unsuccessful Upload. Please make sure to upload a image file.");
        return;
      }
      if (FileSize > 10000000) {
        alert(
          "Unsuccessful Upload. Please make sure to upload file size less than 10 MB."
        );
        return;
      }

      if (file.type.match("image.*")) {
        console.log("thumnail:", file);
        this.thumbnail_img = file;
        let brand_footer_img_url = await this.uploadFileBrand(
          file,
          this.initBrand.brand_name,
          this.initBrand.campaign.campaign_name,
          "footer"
        );

        this.updateBrand({ footer_img: brand_footer_img_url });
      }
      e.target.value = null
    },

    deleteProductImg() {
      this.product_img = null;
      this.product_img_pre = null;
      this.product_img_url = null;
      if (this.edit_product.product_image) {
        this.edit_product.product_image = null
      }
    },
    deleteFooterImg() {
      this.updateBrand({ footer_img: "" });
    },
    deleteHeaderImg() {
      this.updateBrand({ header_img: "" });
    },
    deleteThumbnailImg() {
      this.updateBrand({ thumbnail_img: "" });
    },

    async publishProduct() {
      try {
        const rows = this.$refs.productTable.getSelectedRowData();
        const publish_body = rows.map((row) => {
          return {
            product_id: row.product_id,
            is_publish: true,
          };
        });
        console.log(publish_body);
        const res = await axios.put(
          `${baseurl()}/api/admin/updatePublishProductMultiple`,
          {
            product_id_publish: publish_body,
          }
        );
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.getBrand();
    },
    async unPublishProduct() {
      try {
        const rows = this.$refs.productTable.getSelectedRowData();
        const publish_body = rows.map((row) => {
          return {
            product_id: row.product_id,
            is_publish: false,
          };
        });
        console.log(publish_body);
        const res = await axios.put(
          `${baseurl()}/api/admin/updatePublishProductMultiple`,
          {
            product_id_publish: publish_body,
          }
        );
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.getBrand();
    },
    addTimeStamp(url) {
      // console.log(`${url}?t=${(new Date()).valueOf()}`);
      return `${url}?t=${(new Date()).valueOf()}`
    },

    async ProductUp(val) {
      try {
        await axios.put(`${baseurl()}/api/admin/orderproduct/up`, {
          product_id: val.product_id,
          brand_id: val.brand_id
        })

        await this.getBrand()


      } catch (error) {
        if (error.response.data.message) {
          alert(error.response.data.message)
        }
        else {
          alert(error)
        }
      }
    },
    async ProductDown(val) {
      try {
        await axios.put(`${baseurl()}/api/admin/orderproduct/down`, {
          product_id: val.product_id,
          brand_id: val.brand_id
        })

        await this.getBrand()


      } catch (error) {
        if (error.response.data.message) {
          alert(error.response.data.message)
        }
        else {
          alert(error)
        }
      }
    },

    async exportProduct() {
      try {
        this.$vs.loading({ color: "#DDDDDD" })
        let res = await axios.get(`${baseurl()}/api/excel/exportProduct/${this.initBrand.brand_id}`, {
          responseType: "blob"
        })
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        })
        const elink = document.createElement("a");
        elink.download = `${this.initBrand.campaign.campaign_name.replace(/\s/g, "_")}_${this.initBrand.brand_name.replace(/\s/g, "_")}.xlsx`;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
        this.$vs.loading.close()
        // console.log("res:", res);
      } catch (error) {
        console.log(error);
        if (error.response.data.message) {
          alert(error.response.data.message)
        } else {
          alert(error)
        }
        this.$vs.loading.close()
      }
    },
    async onFileExcelselected(e) {
      try {
        this.$vs.loading({ color: "#DDDDDD" })
        const target = e.target;
        console.log(target.files.length);
        const file = target.files[0];

        console.log("file:", file.type.match("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"));
        const FileSize = file.size;

        console.log("size:", FileSize);
        if (!file.type.match("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          alert("Unsuccessful Upload. Please make sure to upload a xlsx file.");
          return;
        }
        if (FileSize > 10000000) {
          alert(
            "Unsuccessful Upload. Please make sure to upload file size less than 10 MB."
          );
          return;
        }

        if (file.type.match("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          console.log(file);
          let fd = new FormData()
          fd.append("brand_id", this.initBrand.brand_id)
          fd.append("file", file)

          await axios.post(`${baseurl()}/api/excel/importProduct`, fd)

          await this.getBrand()

        }
        this.$vs.loading.close()

      } catch (error) {
        if (error.response.data.message) {
          alert(error.response.data.message)
        }
        else if (error.response.data) {
          alert(error.response.data)
        }
        else {
          alert(error)
        }
        this.$vs.loading.close()
      }
      e.target.value = null
    },
    async onMultiFileProductSelected(e) {
      try {
        this.$vs.loading({ color: "#DDDDDD" })
        const target = e.target;
        console.log(target.files);
        // this.multi_product_img = target.files
        // console.log(this.multi_product_img.length);

        for await (let [index, file] of Object.entries(target.files)) {
          console.log("key:", index);
          console.log("val:", file);

          console.log("img:", file.type.match("image.*"))

          if (!file.type.match("image.*")) {
            alert("Unsuccessful Upload. Please make sure to upload a image file.");
            return;
          }
          if (file.size > 10000000) {
            alert(
              "Unsuccessful Upload. Please make sure to upload file size less than 10 MB."
            );
            return;
          }

          let fd = new FormData()
          fd.append("brand_id", this.initBrand.brand_id)
          fd.append("file", file)

          let res = await axios.post(`${baseurl()}/api/website/uploadSKU`, fd)
          console.log(res);
        }

        await this.getBrand()
        this.$vs.loading.close()
      } catch (error) {
        if (error.response.data.message) {
          alert(error.response.data.message)
        } else {
          alert(error)
        }
        this.$vs.loading.close()
      }
      e.target.value = null
    },
    isAllowClick(val){
      this.allowClick = val
    },
    async updateBrandIsPublish(){
      try {
        await axios.put(`${baseurl()}/api/admin/updatePublishBrand`, {
          "brand_id": this.initBrand.brand_id,
          "is_publish": this.initBrand.is_publish
        })
      } catch (error) {
        if(error.response.data.message){
          alert(error.response.data.message)
        }else{
          alert(error)
        }
      }
    },

    async updateBrandHtml(){
      try {
        let res = await axios.put(`${baseurl()}/api/website/updatehtml/brand`, {
          brand_id: this.initBrand.brand_id
        })
        if (res.status === 200) {
          await this.getBrand()
          alert("success")
        }
      } catch (error) {
        if(error.response.data.message){
          alert(error.response.data.message)
        }else{
          alert(error)
        }
      }     
    },

    OpenImageModal(url){
      this.modal_img_url = url
      this.showPreviewImage = true
    },
    closePreviewImage(){
      this.modal_img_url = null
      this.showPreviewImage = false
    }
  },

  async mounted() {
    this.$vs.loading.close();
    // await this.$store.dispatch("general/push_activePage", {
    // url: "/",
    // name: this.initBrand.brand_name,
    // slug: "brand",
    // });
  },

  computed: {
    formatDate() {
      // return  moment(this.initbrand.lastest_update).format("DD/MM/YY HH:MM:SS")
      return moment(new Date()).format("DD/MM/YY HH:MM:SS")
    }
  },


  components: {
    AgGrid,
    ProductTable,
    BlankModal,
    MiniBlankModal
  },
};

const getBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => reject(error);
  });
};
</script>

<style lang="scss" scoped>
.img-box {
  border: 1px solid #6a7e91;
  border-radius: 10px;
  width: 15rem;
  height: 20rem;
  background-color: #ffffff;
}

.new-dropdown {
  position: absolute;
}

.img-element {
  width: 14.8rem;
  height: 19.8rem;
}

.img-modal{
  width: 40vw;
}

.cus-dropdown ::v-deep .btn-group {
  button {
    padding: .5rem .5rem !important;
    border-radius: 8px;
    border: solid 1px #92A3B7 !important;
    // color: #6A7E91;
  }
}


.cus-select ::v-deep .custom-select{
  border: 1px solid #6A7E91;
}

</style>
