<template>
  <div @click="edit">
    <img class="pointer" src="@/assets/images/elements/pensil.svg" alt="" />
  </div>
</template>

<script>
export default {
  methods: {
    edit() {
      this.params.editValue(this.params.value);
    },
  },
};
</script>

<style lang="sass" scoped></style>
